import React from "react"

import Layout from "../components/layout"
import Head from "../components/Head"
import LicenseProfile from "../components/clients/licenseProfile/LicenseProfile"
import { graphql } from "gatsby"

export default function MemberRecurringPaymentPolicyPage({ data }) {
  const post = data.markdownRemark
  const date = post.frontmatter.date
  const slug = post.fields.slug
  const country = post.frontmatter.country
  let hotLine
  if(country.startsWith("lt_")){
    hotLine = data.site.siteMetadata.countries['lt']['hotLine']
  }else if(country.startsWith("cy_")){
    hotLine = data.site.siteMetadata.countries['cy']['hotLine']
  }else{
    hotLine = data.site.siteMetadata.countries[country]['hotLine']
  }

  const brand = process.env.GATSBY_BRAND;
  const { licenses }=data.license
  const licensesByCountry = licenses.filter((license) => license.frontmatter.country === country && license.frontmatter.subcategory === "policy").reverse()

  return (
    <div className='policy'>
      <Head
        lang={country}
        title={"Payment Policy" + ' ' + date}
        slug={slug}
      />
      <Layout
        country={country}
        hotLine={hotLine}
        countrySwitchOff={true}
      >
        <LicenseProfile
        title={post.frontmatter.title}
        date={date}
        html={post.html}
        currentSlug={slug}
        licenses = {licensesByCountry}
      />
      </Layout>
    </div>
  )
}

export const query = graphql`
  query MemberRecurringPaymentPolicyQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "DD.MM.YYYY")
        country
      }
    }
    license: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(member_recurring_payment_policy)/" } }
    ) {
      licenses: nodes {
        fields {
          slug
        }
        frontmatter {
          title
          country
          subcategory
          date(formatString: "DD.MM.YYYY")
        }
        id
      }
    }
    site {
      siteMetadata {
        senderPath
        countries {
          by {
            hotLine {
              tel
              title
            }
          }
          ru {
            hotLine {
              tel
              title
            }
          }
          am {
            hotLine {
              tel
              title
            }
          }
          lt {
            hotLine {
              tel
              title
            }
          }
          cy {
            hotLine {
              tel
              title
            }
          }
        }
      }
    }
  }
`
